import * as React from "react";
import PageHelmet from "../components/common/helmet";
import { Col, Container, Image, Row } from "react-bootstrap";

const css = require("./index.module.css");

interface Project {
  name: string;
  year: string;
  desc: string;
  url: string;
}

// markup
const IndexPage = () => {
  const social = [
    {
      name: "GITHUB",
      url: "https://github.com/daussho",
    },
    {
      name: "LINKEDIN",
      url: "https://www.linkedin.com/in/daussho/",
    },
    {
      name: "TOOLS",
      url: "/tools",
    },
  ];

  const projectList: Project[] = [
    {
      name: "Suryanara Foundation",
      year: "2020",
      desc: "A foundation website",
      url: "https://www.suryanarafoundation.org/",
    },
    {
      name: "Future10",
      year: "2020",
      desc: "An online concert website",
      url: "https://www.future10.com/",
    },
    {
      name: "Ayana CRM",
      year: "2020",
      desc: "A CRM web based app for managing members",
      url: "#",
    },
    {
      name: "Freezy Fresh",
      year: "2021",
      desc: "An e-commerce web app for fresh products",
      url: "https://freezyfresh.com/",
    },
    {
      name: "Wakalahmu",
      year: "2021",
      desc: "An insurance website, for registration and managed insurance member",
      url: "https://gojek.wakalahmu.com/",
    },
  ];

  const skills = [
    { name: "Languages", val: ["PHP", "JavaScript", "TypeScript", "Go"] },
    { name: "Frameworks", val: ["React", "Vue.js", "Laravel", "CodeIgniter"] },
    { name: "Databases", val: ["MySQL", "Redis"] },
    { name: "Others", val: ["REST API", "GraphQL"] },
  ];

  return (
    <>
      <PageHelmet title="Abdul Hafizh Firdaus | Full-Stack Web Developer" />

      <Container className="py-5">
        <Row>
          <Col xs={6} md={2} className="pb-3">
            <Image
              src="/assets/images/profile.jpg"
              roundedCircle
              style={{
                width: "100%",
              }}
            />
          </Col>
          <Col xs={12} md="auto">
            <h1 className="mb-0">Abdul Hafizh Firdaus</h1>
            <p className="text-muted">
              Full-Stack Web Developer&nbsp;-&nbsp;
              <a
                href="https://www.astronauts.id/"
                target="_blank"
                className={css.linkMuted}
              >
                Astro, Jakarta, Indonesia
              </a>
            </p>
            <p className="text-muted mt-5">
              {social.map((el, i) => (
                <span key={i}>
                  <a href={el.url} target="_blank" className={css.linkMuted}>
                    {el.name}
                  </a>
                  &nbsp;&nbsp;
                </span>
              ))}
            </p>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col xs={12} lg={7} className="px-3">
            <p>
              I'm specialized in building web applications. I'm experienced both
              in front-end and back-end. I’ve been working in my field for more
              than 3 years. I am passionate about what I do, it shows in my work
              and proven track record.
            </p>
          </Col>
        </Row>

        <Divider />

        <Row>
          <h5>PROJECTS</h5>

          {projectList.reverse().map((project, i) => (
            <div key={i} className="mt-3">
              <h6>
                <a href={project.url} target="_blank" className={css.linkMuted}>
                  {project.name}
                  <span className="text-muted">
                    &nbsp;-&nbsp;{project.year}
                  </span>
                </a>
              </h6>
              <p>{project.desc}</p>
            </div>
          ))}
        </Row>

        <Divider />

        <Row>
          <h5>SKILLS</h5>

          {skills.map((skill, i) => (
            <div key={i} className="mt-3">
              <h6>{skill.name}</h6>
              <p>{skill.val.join(", ")}</p>
            </div>
          ))}
        </Row>
      </Container>
    </>
  );
};

const Divider = () => {
  return (
    <Row>
      <Col xs={12} lg={7}>
        <hr className="my-3" />
      </Col>
    </Row>
  );
};

export default IndexPage;
